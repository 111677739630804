import { useTextField } from '@react-aria/textfield';
import * as React from 'react';
import { forwardRef, memo, RefObject, useRef } from 'react';

import type { FontSizeVals, HeightVals, IMarginProps } from '../../enhancers';
import { SpaceVals } from '../../enhancers';
import { splitBoxProps } from '../../utils';
import { Badge } from '../Badge';
import { Box } from '../Box';
import type { BoxOwnProps, IBoxHTMLAttributes } from '../Box/types';
import { HStack } from '../Stack';

export interface ITagInputProps extends IMarginProps, BoxOwnProps<React.ElementType<'div'>> {
  size?: 'sm' | 'md' | 'lg';
  readOnly?: boolean;
  values: (string | number)[];
  uniqueValues?: boolean;
  onChange?(values: (string | number)[]): void;
}

const fontSizes: Partial<Record<HeightVals, FontSizeVals>> = {
  lg: 'lg',
  md: 'base',
  sm: 'base',
};

const sizes: Partial<Record<HeightVals, { px: SpaceVals }>> = {
  lg: { px: 3 },
  md: { px: 2.5 },
  sm: { px: 1.5 },
};

export const TagInput = memo(
  forwardRef<HTMLDivElement, ITagInputProps>(function TagInput(
    { size = 'md', className, readOnly, values: _values, uniqueValues = true, onChange, ...props },
    ref: RefObject<HTMLDivElement>,
  ) {
    const { matchedProps, remainingProps } = splitBoxProps(props);
    const [value, setValue] = React.useState('');
    const values = React.useMemo(() => (uniqueValues ? [...new Set(_values)] : _values), [_values, uniqueValues]);

    const handleKeyUp = React.useCallback<React.KeyboardEventHandler>(
      e => {
        if (readOnly) return;

        switch (e.key) {
          case 'Enter': {
            const actualValue = value.trim();
            if (actualValue.length === 0) return;
            if (uniqueValues && values.includes(value)) return;

            onChange?.([...values, value]);
            setValue('');
            break;
          }

          case 'Backspace': {
            if (values.length > 0 && value.length === 0) {
              const newValues = [...values];
              newValues.pop();
              onChange?.(newValues);
            }

            break;
          }
        }
      },
      [onChange, values, uniqueValues, value, readOnly],
    );

    const handleInput = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => {
      setValue(e.target.value);
    }, []);

    const inputRef = useRef();
    const {
      inputProps: { color, ...inputProps },
    } = useTextField(
      {
        ...remainingProps,
        'aria-label': 'Enter new value',
        onInput: handleInput,
        onKeyUp: handleKeyUp,
        isReadOnly: readOnly,
        value,
      },
      inputRef,
    );

    const disabledProps: Partial<IBoxHTMLAttributes & BoxOwnProps> = readOnly
      ? {
          tabIndex: -1,
        }
      : {};

    return (
      <HStack
        border
        borderColor={{ default: 'input', focusWithin: 'primary' }}
        pos="relative"
        overflowY="hidden"
        overflowX="auto"
        px={sizes[size].px}
        ref={ref}
        rounded
        spacing={1}
        {...matchedProps}
        {...disabledProps}
      >
        {values.map((value, i) => (
          <Badge
            key={i}
            aria-label={String(value)}
            data-testid={`tag-input-badge-${i}`}
            size={size}
            w="full"
            onRemove={
              !readOnly &&
              (() => {
                const newValues = [...values];
                newValues.splice(i, 1);
                onChange?.(newValues);
              })
            }
          >
            {value}
          </Badge>
        ))}
        <Box
          as="input"
          ref={inputRef}
          data-testid="tag-input-input"
          fontSize={fontSizes[size]}
          h={size}
          w="full"
          readOnly={readOnly}
          {...disabledProps}
          {...inputProps}
        />
      </HStack>
    );
  }),
);
