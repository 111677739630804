import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo, RefObject } from 'react';

import { IMarginProps, IPaddingProps } from '../../enhancers';
import { PolymorphicComponentProps } from '../Box';
import { Flex } from '../Flex';

export type ButtonGroupOwnProps = IPaddingProps &
  IMarginProps & {
    // Expects children to be Buttons.
    children: React.ReactNode;
    className?: string;
  };

export type ButtonGroupProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  ButtonGroupOwnProps
>;

const defaultElement = 'div';

export const ButtonGroup: <E extends React.ElementType = typeof defaultElement>(
  props: ButtonGroupProps<E> & { ref?: RefObject<HTMLDivElement> },
) => JSX.Element = memo(
  forwardRef(
    <E extends React.ElementType>(
      { children, className, ...rest }: ButtonGroupProps<E>,
      ref: RefObject<HTMLDivElement>,
    ) => {
      return (
        <Flex
          as={defaultElement}
          {...rest}
          justifyItems="start"
          alignItems="center"
          className={cn('sl-button-group', className)}
          ref={ref}
        >
          {children}
        </Flex>
      );
    },
  ),
);
