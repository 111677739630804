import { API } from "@stoplight/elements";
import { TABS } from "./constants.js";

const Documentation = ({ activeTab, apiDescriptionDocument }) => {
  switch (activeTab) {
    case TABS.CORE:
      return (
        <API
          logo="https://gateway.pinata.cloud/ipfs/QmUtWs3xnPiA8c45BLzj8seZW4Pbhpy4ScD7jX65ua8nhj"
          apiDescriptionDocument={apiDescriptionDocument}
        />
      );
    case TABS.ENS_INDEX:
      return (
        <iframe
          title="TEST"
          width="100%"
          height="100%"
          src="https://ens-api-dev.up.railway.app/docs/"
        />
      );
    default:
      throw new Error("Unsupported tab.");
  }
};

export default Documentation;
