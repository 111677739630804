import { useSwitch } from '@react-aria/switch';
import { mergeProps } from '@react-aria/utils';
import { useToggleState } from '@react-stately/toggle';
import { SwitchBase } from '@react-types/switch';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo, RefObject, useRef } from 'react';

import { useThemeIsDark } from '../../hooks';
import { splitBoxProps } from '../../utils';
import { Box } from '../Box';
import { PolymorphicComponentProps } from '../Box/types';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { sizes } from './variants';

export type SwitchOwnProps = SwitchBase & {
  size?: 'sm' | 'md' | 'lg';
  showIcon?: boolean;
};

export type SwitchProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  SwitchOwnProps
>;

const defaultElement = 'input';

export const Switch: <E extends React.ElementType = typeof defaultElement>(
  props: SwitchProps<E> & { ref?: RefObject<HTMLInputElement> },
) => JSX.Element = memo(
  forwardRef(
    <E extends React.ElementType>(
      {
        size = 'md',
        showIcon = false,
        className,
        isDisabled,
        isSelected,
        defaultSelected,
        onChange,
        ...props
      }: SwitchProps<E>,
      ref?: RefObject<HTMLInputElement>,
    ) => {
      const fallbackRef = useRef();
      const inputRef = ref || fallbackRef;
      const isDark = useThemeIsDark();
      const state = useToggleState({ onChange, isSelected, defaultSelected, ...props });
      const { inputProps } = useSwitch({ isDisabled, ...props }, state, inputRef);
      const { matchedProps, remainingProps } = splitBoxProps(props);

      let disabledProps = {};
      if (isDisabled) {
        disabledProps = {
          bg: isDark ? 'canvas-100' : 'canvas-200',
          cursor: 'not-allowed',
        };
      }

      const checkboxProps = mergeProps(inputProps, { color: undefined });

      return (
        <Box
          className={cn('sl-switch', className)}
          h={sizes[size].height}
          w={sizes[size].width}
          fontSize={sizes[size].fontSize}
          pos="relative"
          display="block"
          userSelect="none"
          {...matchedProps}
          {...remainingProps}
        >
          <Box as="label" pos="absolute" w="full" h="full">
            <Box
              ref={inputRef}
              as={defaultElement}
              pos="absolute"
              m={0}
              zIndex={-1}
              top={0}
              left={0}
              opacity={0}
              disabled={isDisabled}
              {...checkboxProps}
            />

            <Box
              as="span"
              cursor="pointer"
              className="sl-switch__indicator"
              rounded="full"
              w="full"
              h="full"
              display="block"
              bg={isDark ? 'canvas-50' : 'canvas-500'}
              {...disabledProps}
            >
              {showIcon && (
                <Flex
                  className="sl-switch__icon"
                  pos="absolute"
                  align="center"
                  style={{ top: 0, bottom: 0, left: 0, lineHeight: 1 }}
                  pl={1}
                  color={isDark ? 'body' : 'canvas-pure'}
                >
                  <Icon icon="check" fixedWidth />
                </Flex>
              )}
            </Box>
          </Box>
        </Box>
      );
    },
  ),
);
