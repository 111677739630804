import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const BasicAuthModal = ({ onSucces }) => {
  const [showModal, setShowModal] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => setShowModal(false);

  const onEnterHandler = (keyUpEvent) => {
    if (keyUpEvent.code === "Enter") {
      handleSubmit(keyUpEvent);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("https://poop.interface.social/insights/api-docs", {
      method: "GET",
      headers: {
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
        "Content-Type": "text/json",
      },
    })
      .then((res) => res.json())
      .then((data) => onSucces(data));
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>gm</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid gap-2">
          <Form onSubmit={handleSubmit} onKeyUp={onEnterHandler}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" size="lg" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BasicAuthModal;
