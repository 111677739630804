import { useState } from "react";
import BasicAuthModal from "./Auth.jsx";
import Documentation from "./Documentation.jsx";
import Navbar from "./Navbar.jsx";
import { TABS } from "./constants.js";

import "@stoplight/elements/styles.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

function App() {
  const [apiDescriptionDocument, setApiDescriptionDocument] = useState();
  const [activeTab, setActiveTab] = useState();

  return (
    <div className="App">
      <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === TABS.CORE ? (
        apiDescriptionDocument ? (
          <Documentation
            activeTab={activeTab}
            apiDescriptionDocument={apiDescriptionDocument}
          />
        ) : (
          <BasicAuthModal onSucces={setApiDescriptionDocument} />
        )
      ) : null}
    </div>
  );
}

export default App;
