import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, LegacyRef, memo, ReactNode, RefObject } from 'react';

import { IntentVals } from '../../enhancers';
import { Box, PolymorphicComponentProps } from '../Box';
import { Stack } from '../Stack';
import { RadioContext } from './context';
import { RadioEnhancerProps, variants } from './variants';

export type RadioGroupOwnProps = RadioEnhancerProps & {
  label: ReactNode;
  children?: React.ReactNode;
  isDisabled?: boolean;
  intent?: IntentVals;
  size?: 'sm' | 'md';
  autoFocus?: boolean;
};

const defaultElement = 'div';

export type RadioGroupProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  RadioGroupOwnProps
>;

export const RadioGroup: <E extends React.ElementType = typeof defaultElement>(
  props: RadioGroupProps<E> & { ref?: RefObject<HTMLElement> },
) => JSX.Element = memo(
  forwardRef(
    <E extends React.ElementType>(
      {
        intent = 'default',
        size = 'md',
        orientation = 'vertical',
        name,
        className,
        label,
        isDisabled,
        children,
        ...props
      }: RadioGroupProps<E>,
      ref: RefObject<HTMLElement>,
    ) => {
      let state = useRadioGroupState(props);
      let { radioGroupProps, labelProps } = useRadioGroup({ label }, state);

      const variant: Partial<RadioEnhancerProps> = {
        ...variants.default,
        ...variants[intent],
      };

      const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { intent, variant, name, size, isDisabled, ...child.props });
        }
        return child;
      });

      const radioBoxProps = {
        color: variant.color,
      };

      return (
        <Stack
          as={defaultElement}
          direction={orientation}
          ref={ref as LegacyRef<HTMLDivElement>}
          className={cn('sl-radio-group', className)}
          spacing={2}
          {...radioGroupProps}
          {...radioBoxProps}
          {...props}
        >
          {label && (
            <Box pr={orientation === 'horizontal' ? 3 : undefined}>
              <span {...labelProps}>{label}</span>
            </Box>
          )}
          <RadioContext.Provider value={state}>{childrenWithProps}</RadioContext.Provider>
        </Stack>
      );
    },
  ),
);
