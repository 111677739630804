import { TABS } from "./constants.js";

const Navbar = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <ul className="nav">
        <li className="nav-item">
          <a
            href="#"
            className={`nav-link ${activeTab === TABS.CORE ? "active" : ""}`}
            onClick={() => setActiveTab(TABS.CORE)}
          >
            {TABS.CORE}
          </a>
        </li>
        <li className="nav-item">
          <a
            href="https://ens-api-dev.up.railway.app/docs"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            {TABS.ENS_INDEX}
          </a>
        </li>
      </ul>
    </>
  );
};

export default Navbar;
